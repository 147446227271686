import type { CrossSelling } from "@shopware-pwa/types";
import type { ShopwareSearchParams } from "@shopware-pwa/types";
import merge from "lodash/merge";

type Options = {
	includeMedia?: boolean;
	includeOptions?: boolean;
	includeProperties?: boolean;
	includeAllergens?: boolean;
};

export type BaseCrossSellChannel = Pick<CrossSelling["crossSelling"], "id" | "name">;

export const generateCriteria = (
	options: Options = {},
	overwrite?: Partial<Pick<ShopwareSearchParams, "associations" | "includes">>
): Pick<ShopwareSearchParams, "associations" | "includes"> => {
	const criteria: Required<Pick<ShopwareSearchParams, "associations" | "includes">> = {
		includes: {},
		associations: {}
	};

	// Add first cross selling
	criteria.includes.product_cross_selling = ["id", "name", "translated.name"];
	criteria.associations.crossSellings = {
		// @ts-ignore
		limit: 1
	};

	if (options.includeMedia) {
		criteria.associations.media = {};
	}

	if (options.includeOptions) {
		criteria.associations.options = {};
	}

	if (options.includeAllergens) {
		criteria.associations.allergens = {};
	}

	if (options.includeProperties) {
		criteria.associations.properties = {
			associations: {
				group: {}
			}
		};
	}

	return merge(criteria, overwrite);
};
